import { i18nCommons, setLocales } from '@geovelo-frontends/commons';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes } from 'react-router-dom';

import routes from './routes';

function Router(): JSX.Element {
  const { i18n } = useTranslation();

  useEffect(() => {
    i18nCommons.changeLanguage(i18n.language);
    setLocales(i18n.language);
  }, [i18n.language]);

  return (
    <Routes>
      {routes.map(({ element, ...otherProps }) => {
        return <Route element={element} key={otherProps.path || 'index'} {...otherProps} />;
      })}
      <Route element={<Navigate to="/" />} path="*" />
    </Routes>
  );
}

export default Router;
