import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

import Header from './header';

function Layout(): JSX.Element {
  return (
    <>
      <Header />
      <Box display="flex" flexDirection="column" width="100%">
        <Outlet />
      </Box>
    </>
  );
}

export default Layout;
