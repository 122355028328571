import { useAmplitudeTracker } from '@geovelo-frontends/commons';
import { ReactNode, Suspense, lazy, useEffect, useState } from 'react';
import { Route, RouteProps, useLocation } from 'react-router-dom';

import Layout from '../../layouts/default';

const EventPage = lazy(() => import('../../pages/event'));
const EventsPage = lazy(() => import('../../pages/events'));
const HomePage = lazy(() => import('../../pages/home'));

export type TPaths = '/';

const routes: Array<
  Omit<RouteProps, 'path' | 'index' | 'lazy'> & {
    path?: TPaths | '*';
  }
> = [
  {
    path: '/',
    element: <Layout />,
    children: (
      <>
        <Route
          index
          element={
            <RouteElement pageName="home_page">
              <HomePage />
            </RouteElement>
          }
        />
        <Route
          element={
            <RouteElement pageName="current_events_page">
              <EventsPage period="current" />
            </RouteElement>
          }
          path="current"
        />
        <Route
          element={
            <RouteElement pageName="future_events_page">
              <EventsPage period="future" />
            </RouteElement>
          }
          path="future"
        />
        <Route
          element={
            <RouteElement pageName="past_events_page">
              <EventsPage period="past" />
            </RouteElement>
          }
          path="past"
        />
        <Route
          element={
            <RouteElement pageName="event_page">
              <EventPage />
            </RouteElement>
          }
          path=":id"
        />
      </>
    ),
  },
];

function RouteElement({
  pageName,
  children,
}: {
  children: ReactNode;
  pageName?: string;
}): JSX.Element {
  const [initialized, setInitialized] = useState(false);
  const { trackEvent } = useAmplitudeTracker();
  const { pathname } = useLocation();

  useEffect(() => {
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized && pageName) {
      window.scrollTo(0, 0);
      trackEvent('Page Visited', {
        pathname,
        page_name: pageName,
      });
    }
  }, [initialized, pageName]);

  return <Suspense fallback={<></>}>{children}</Suspense>;
}

export default routes;
