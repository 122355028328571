import { GeoveloInlineWhiteIcon } from '@geovelo-frontends/commons';
import { AppBar, ThemeProvider, Toolbar } from '@mui/material';
import { Link } from 'react-router-dom';

import { darkTheme } from '../../app/theme';

function Header(): JSX.Element {
  return (
    <ThemeProvider theme={darkTheme}>
      <AppBar color="default" elevation={0} position="absolute" sx={{ backgroundColor: '#528BE8' }}>
        <Toolbar>
          <Link style={{ height: 30 }} to="/">
            <GeoveloInlineWhiteIcon sx={{ height: 30, width: '100%' }} />
          </Link>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}

export default Header;
